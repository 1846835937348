var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('section',{staticClass:"my-account"},[_c('h1',{staticClass:"my-account__header"},[_vm._t("header")],2),_c('div',{staticClass:"my-account__nav"},[_c('ul',{staticClass:"account-nav"},_vm._l((_vm.routes),function(ref){
var name = ref.name;
var label = ref.label;
return _c('router-link',{key:name,attrs:{"to":{ name: name },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"account-nav__item",class:{
              'account-nav__item--active': isActive,
              'account-nav__item--exact-active': isExactActive,
            }},[_c('a',{staticClass:"account-nav__link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(label)+" ")])])]}}],null,true)})}),1)]),_c('div',{staticClass:"my-account__content"},[_vm._t("content")],2),_c('p',{staticClass:"my-account__log-out"},[_vm._t("log-out")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }