<template>
  <div class="container">
    <section class="my-account">
      <h1 class="my-account__header">
        <slot name="header" />
      </h1>

      <div class="my-account__nav">
        <ul class="account-nav">
          <router-link
            v-for="{ name, label } in routes"
            :key="name"
            v-slot="{ href, route, navigate, isActive, isExactActive }"
            :to="{ name }"
            custom
          >
            <li
              class="account-nav__item"
              :class="{
                'account-nav__item--active': isActive,
                'account-nav__item--exact-active': isExactActive,
              }"
            >
              <a :href="href" class="account-nav__link" @click="navigate">
                {{ label }}
              </a>
            </li>
          </router-link>
        </ul>
      </div>

      <div class="my-account__content">
        <slot name="content" />
      </div>

      <p class="my-account__log-out">
        <slot name="log-out" />
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MyAccountTemplate',

  data: () => ({
    routes: [
      {
        name: 'orders',
        label: 'Zamówienia',
      },
      {
        name: 'user-settings',
        label: 'Ustawienia konta',
      },
    ],
  }),
};
</script>
